'use strict'

import state from '../util/state';

const Pagetop = class {
  constructor() {
    this.tgt = {
			btn: document.querySelector('.pagetop')
    }
    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 50));
    this.scrl();
    // console.log('scroll');
  }
  scrl() {
    // const pos = document.querySelector('footer.global').getBoundingClientRect().top + this.tgt.spc;
    if (window.pageYOffset > 50) {
      this.tgt.btn.classList.add('active');
    } else {
      this.tgt.btn.classList.remove('active');
    }
  }
};
export default Pagetop;
