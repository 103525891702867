'use strict'

/* =================================================
pageState
==================================================== */

const state = {
	dev: 'pc',
	nav: false,
	fixed: false,
	land: false,
	lospec: false,
	stage: false
};

export default state;
