'use strict'

import _ from 'lodash';
import state from '../util/state';

const Header = class {
  constructor() {
    const urlPath = location.pathname;

    if(urlPath === '/') {
      document.querySelector('.gnav_top').classList.add('current');
    } else if(urlPath.indexOf('stay') > -1) {
      document.querySelector('.gnav_stay').classList.add('current');
    } else if(urlPath.indexOf('eat-drink') > -1) {
      document.querySelector('.gnav_eat-drink').classList.add('current');
    } else if(urlPath.indexOf('facilities') > -1) {
      document.querySelector('.gnav_facilities').classList.add('current');
    }
  }
};
export default Header;
