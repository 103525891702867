'use strict'

import $ from 'jquery';
import { InViewPort } from 'in-view-js';
import state from '../util/state';

const Inview = class {
  constructor() {
    const tgt = $('.js-in');
    // const tgt = (state.dev === 'pc') ? $('.js-in-pc') : $('.js-in-sp');

    tgt.each(function(){
      let element = this,
        callback = function(isInView){

          if(isInView){
            $(element).addClass('inview');
          }else{
            // $(element).removeClass('inview');
          }
        };
      let inViewInstance = new InViewPort({
        element,
        callback
      });

      inViewInstance.addListeners();
    });
    window.scroll({ top: 1 });
  }
}

export default Inview;
