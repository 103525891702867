'use strict'

import _ from 'lodash';

const Scroll = class {
  constructor() {
    this.target = document.querySelector('.js-scroll-contents');
    this.dummy = document.querySelector('.js-scroll-dummy');
    this.dummy.style.height = this.target.clientHeight + 'px';

    window.addEventListener('scroll', this.domScroll.bind(this));
    window.addEventListener('resize', _.throttle(() => {
      this.dummy.style.height = this.target.clientHeight + 'px';
    }, 500));
  }
  domScroll() {
    const pageYOffset = window.pageYOffset * -1;
    this.target.style.transform = 'translate3d(' + 0 + ',' +  pageYOffset + 'px ,' + 0 + ')';
  }
};
export default Scroll;
