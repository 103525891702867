'use strict'

import $ from 'jquery';
import _ from 'lodash';
import magnificPopup from 'magnific-popup';
import state from '../util/state';
// import $ from 'jquery';

const Top = class {
  constructor() {
    this.kv();
    // this.popModal();

    window.onload = function(){
      document.querySelector('.kv').classList.add('ready');
    };
  }
  kv() {
    var videoH = window.innerHeight - 181 - 74;
    if(state.dev === 'sp') {
      document.querySelector('body.top .kv').style.minHeight = videoH + 'px';
      document.querySelector('body.top .kv .movie').style.minHeight = videoH + 'px';
      document.querySelector('body.top .kv .video').style.minHeight = videoH + 'px';
      // document.querySelector('.kv video').style.height = '350px';
    }
  }
  popModal() {
    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 150,
      preloader: false,
      fixedContentPos: false,
      mainClass: 'my-mfp-slide-bottom',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>'
    });
  }
};
export default Top;
