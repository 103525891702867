'use strict'

// import $ from 'jquery';
import state from '../util/state';

const Gmap = class {
  constructor(el) {
    const lat = parseFloat(document.querySelector('.js-map').getAttribute('data-lat')),
          lng = parseFloat(document.querySelector('.js-map').getAttribute('data-lng'));
    // console.log(lat);
    // console.log(lng);

    // Base
    const latlng = new google.maps.LatLng(lat,lng);
    const devZoom = (state.dev === 'sp') ? 14 : 14;
    const myOptions = {
      zoom: devZoom,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      fullscreenControl: true,
      streetViewControl: false,
      zoomControl: true

    };
    const map = new google.maps.Map(document.querySelector('.js-map'), myOptions);

    // Add Style
    const styleOptions = [
      {
        "featureType": "all",
        "stylers": [
          {
            "saturation": 0
          },
          {
            "hue": "#869da9"
          }
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {
            "saturation": -70
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            // "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            // "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "visibility": "simplified"
          },
          {
            "saturation": -60
          }
        ]
      }
    ];

    const styledMapOptions = { name: '' }
    const grayType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
    map.mapTypes.set('sample', grayType);
    map.setMapTypeId('sample');

    // Add Marker
    // const icon = new google.maps.MarkerImage('/assets/img/common/ico_pin.svg',
    // new google.maps.Size(50,58), // icon size
    // new google.maps.Point(0,0) //icon position
    // );
    const markerOptions = {
      position: latlng,
      map: map,
      // icon: icon,
      title: ''
    };
    const marker = new google.maps.Marker(markerOptions);

  }
};
export default Gmap;
