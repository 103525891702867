'use strict'

import $ from 'jquery';
import _ from 'lodash';
import magnificPopup from 'magnific-popup';
import state from '../util/state';

const Gallery = class {
  constructor() {
    this.galModal();
  }
  galModal() {

    $('.js-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      closeBtnInside: false,
      image: {
        markup:
          '<div class="mfp-figure">'+
            '<div class="mfp-img"></div>'+
            '<div class="mfp-counter"></div>'+
            // '<div class="mfp-close" role="button">CLOSE</div>'+
            // '<button title="Previous" type="button" class="current mfp-arrow mfp-arrow-left"></button>'+
            // '<button title="Next" type="button" class="current mfp-arrow mfp-arrow-right"></button>'+
          '</div>',
      },
      gallery: {
        enabled: true,
        tCounter: '<span class="mfp-counter">%curr% / %total%</span>'
      },
      removalDelay: 100,
      mainClass: 'my-mfp-slide-bottom',

      // type:'inline',
      // midClick: true,
      // removalDelay: 300,
      // mainClass: 'my-mfp-slide-bottom',
      // callbacks: {
      //   open: function() {
      //   // Will fire when this exact popup is opened
      //   // this - is Magnific Popup object
      //     $('html,body').addClass('mdl-open');
      //     console.log('open');
      //   },
      //   close: function() {
      //     // Will fire when popup is closed
      //     $('html,body').removeClass('mdl-open');
      //     console.log('close');
      //   }
      // }
    });

    $('.mdl-right').on('click', function() {
      console.log('next');
      magnificPopup.next();
    });
  }
};
export default Gallery;
