import './polyfill'

import Header from './modules/Header';
import HeaderNav from './modules/HeaderNav';
import Gmap from './modules/Gmap';
import Scroll from './modules/Scroll';
import Inview from './modules/Inview';
import Link from './modules/Link';
import Pagetop from './modules/Pagetop';
import Top from './modules/Top';
import Gallery from './modules/Gallery';

import state from './util/state';

state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  // new Header();
  // new HeaderNav();
  new Link('.js-link');
  // //
  // if(document.querySelector('.js-map')) {
  //   new Gmap();
  // }
  if(document.querySelector('.pagetop')) {
    new Pagetop();
  }
  if(document.querySelector('body.top')) {
    new Top();
  }
  // if(document.querySelector('.js-gallery')) {
  //   new Gallery();
  // }
  // //
  // document.querySelector('#wrap').classList.add('ready');
  //
  if(document.querySelector('.js-in')) {
    new Inview();
  }
});

window.onload = function(){
  // Load functions
  // if(state.dev === 'pc') {
  //   new Scroll();
  // }
  // Show Kv
  // if(document.querySelector('.kv')) {
  //   document.querySelector('.kv').classList.add('acrive');
  // }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';
  // if(state.dev === 'pc') {
  //   new Scroll();
  // }
}, 500));
