'use strict'

import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state.js';

const Link = class {
  constructor(el) {

    const links = $(el);

    links.each(function(){
      $(this).on('click', function(e){
        e.preventDefault();

        const href = $(this).attr('href');

        if(href.indexOf('#') > -1) {

          // Smooth Scroll
          let target = $(href == '#' || href == '' ? 'html' : href);
          let position = target.offset().top;

          position = (position > 0) ? position - 0 : position;
          $('body,html').animate({scrollTop: position}, 1200);

          return false;
        } else {

          // Fadeout
          $('#wrap').addClass('fadeout');
          setTimeout(function(){
            location.href = href;
          }, 600);
          return false;
        }
      });
    });
  }
}

export default Link;
